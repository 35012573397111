import React, { useEffect } from "react";
// import Mixpanel from "mixpanel-browser";
import { BrowserRouter } from "react-router-dom";
import WrappedRoutes from "../../routes/WrappedRoutes";
import ToastNotification from "../../common/ToastNotification";
import config from "../../config/config";

// Mixpanel.init(config.mixpanelToken);

const Main = () => {

 /*  useEffect(() => {
    let d = new Date().getTime();
    // Create a unique anonymous ID for the user
    let anonymousId = localStorage.getItem("anonymousId");
    if (!anonymousId) {
      localStorage.setItem("anonymousId", d);
    }
    // Alias the anonymous ID with Mixpanel
    Mixpanel.alias(anonymousId);
  }, []); */

  return (
    <BrowserRouter basename={`${process.env.PUBLIC_URL}`}>
      <WrappedRoutes />
      <ToastNotification />
    </BrowserRouter>
  );
};

export default Main;
