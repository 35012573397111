import CreateNew from "../CreateNew";
import CoverPage from "../components/coverpage/CoverPage";

const RouteList = [
    
    {
        label: "Create new",
        isProtected: false,
        Component: CreateNew,
        path: "/create-new",
    },
    {
        label: "Cover Page",
        isProtected: false,
        Component: CoverPage,
        path: "/",
    },
]

export default RouteList