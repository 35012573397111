import React, { useEffect, useRef, useState } from "react";

// import Mixpanel from "mixpanel-browser";

import Step1 from "./components/Step1";

import "./App.css";
import Step2 from "./components/Step2";
import Step3 from "./components/Step3";
import Step4 from "./components/Step4";
import Step5 from "./components/Step5";
import Step6 from "./components/Step6";
import Step7 from "./components/Step7";
import Step8 from "./components/Step8";
import Step9 from "./components/Step9";
import Step10 from "./components/Step10";
import Step11 from "./components/Step11";
import Step12 from "./components/Step12";
import Step13 from "./components/Step13";
import Step14 from "./components/Step14";
import Step15 from "./components/Step15";

import ResponseOfPressRelease from "./components/ResponseOfPressRelease";
import Loading from "./components/Loading";
import { showError } from "./utils/messageHelper";
import { getPressReleaseData } from "./services/api/api";

import prideOfPR from "./assets/images/PrideofPR_Logo.svg";
import { OK } from "./config/status-code";
import MainPage from "./components/MainPage";

function App() {
  const [currentStep, setCurrentStep] = useState(15);

  const data = "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";

  const [loading, setLoading] = useState(false);

  const [clickCount, setclickCount] = useState(0);

  const [dataOfPressRelase, setDataOfPressRelease] = useState("");

  /* useEffect(() => {
    Mixpanel.track("Page Loaded", { Page: "Create new page" });
  }, []); */

  /* const handleMixPanelClick = (stepNumber) => {
    Mixpanel.track("Sidebar button clicked", { Page: `Page ${stepNumber}` });
  }; */

  const initialValues = useRef({
    "This press release is generated by": {
      individual: {
        "with name": "",
        "with contact number": "",
        "with email": "",
        "with address": "",
      },
      company: {
        "company name is": "",
        "company contact number is": "",
        "company email is": "",
        "company address is": "",
        "company website is": "",
        "authorized person for the company is": "",
      },
      "type is": "",
    },
    announcement: {
      "Title of release is": "",
      "detailed explanation for the announcement is": "",
      "type of announcement is": [],
    },
    "Details regarding target audience": {
      "type of targeted audience is": [],
    },
    "Following are the key points regarding this announcement": [
      {
        "title of key point is": "",
        "Description of key point is": "",
      },
    ],
    "Following are the data that we need to add in PR": [
      {
        "Source of the data is": "",
        "Description of the data is": "",
      },
    ],
    "Following are the staticstics that we need to add in PR": [
      { "statistics are": "", "source for the statistic is": "" },
    ],
    "Following are the events that we need to mention in the PR": {
      event: {
        "event title is": "",
        "event is on": "",
        "event description is": "",
      },
      deadline: {
        "deadline title is": "",
        "deadline is": "",
        "deadline description is": "",
      },
      "selected event is": "deadline",
    },
    "This PR is in continuation with previous communications": [
      {
        "As communicated previously through": "",
        "title was": "",
        "other text for communication is": "",
        "The date on which communication was made": "",
        "The communication is available at": "",
      },
    ],
    "Please mention about this industry trends": "",
    "Please add this legal disclaimer": "",
    "Please add the jurisdiction as": "",
    "Following people will be available to contact regarding this": [
      {
        "Contact person name is": "",
        "contact number is": "",
        "Contact person email is": "",
        "Contact address is": "",
        "Department of the contact person is": "",
        "This contact is primary contact": false,
      },
    ],
    "Add this additional note": "",
    "Please ensure the PR with above details are created using this framework":
      "",
    "open AI key is": "",
  });

  const mainFormInitialValue = useRef({
    "The news angle is": "",
    "The company information is": "",
    "The detailed information regarding announcement is": "",
    "Product or service information is": "",
    "Event information is": "",
    "Quote is": "",
    "Contact information is": "",
    "Link of the document is": "",
    "Target audience is": "",
    "Additional information is": "",
  });

  const skipValues = useRef({
    step5: false,
    step6: false,
    step7: false,
    step8: false,
    step9: false,
    step10: false,
    step11: false,
    step12: false,
    step13: false,
  });
  const generateData = async () => {
    if (
      initialValues.current["This press release is generated by"]["type is"] ===
      "individual"
    ) {
      initialValues.current["This press release is generated by"] = {
        individual:
          initialValues.current["This press release is generated by"][
            "individual"
          ],
      };
    } else if (
      initialValues.current["This press release is generated by"]["type is"] ===
      "company"
    ) {
      initialValues.current["This press release is generated by"] = {
        company:
          initialValues.current["This press release is generated by"][
            "company"
          ],
      };
    }

    if (
      initialValues.current[
        "Following are the events that we need to mention in the PR"
      ]["selected event is"] === "event"
    ) {
      initialValues.current[
        "Following are the events that we need to mention in the PR"
      ] = {
        "Need to mention this event":
          initialValues.current[
            "Following are the events that we need to mention in the PR"
          ]["Need to mention this event"],
      };
    } else if (
      initialValues.current[
        "Following are the events that we need to mention in the PR"
      ]["selected event is"] === "deadline"
    ) {
      initialValues.current[
        "Following are the events that we need to mention in the PR"
      ] = {
        "Need to mention this deadline":
          initialValues.current[
            "Following are the events that we need to mention in the PR"
          ]["Need to mention this deadline"],
      };
    }
    const apiKeyData = initialValues.current["open AI key is"];

    delete initialValues.current["open AI key is"];
    skipValues.current["step5"] === true &&
      delete initialValues.current[
        "Following are the data that we need to add in PR"
      ];
    skipValues.current["step6"] === true &&
      delete initialValues.current[
        "Following are the staticstics that we need to add in PR"
      ];
    skipValues.current["step7"] === true &&
      delete initialValues.current[
        "Following are the events that we need to mention in the PR"
      ];
    skipValues.current["step8"] === true &&
      delete initialValues.current[
        "This PR is in continuation with previous communications"
      ];
    skipValues.current["step9"] === true &&
      delete initialValues.current["Please mention about this industry trends"];
    skipValues.current["step10"] === true &&
      delete initialValues.current["Please add this legal disclaimer"];
    skipValues.current["step11"] === true &&
      delete initialValues.current["Please add the jurisdiction as"];
    skipValues.current["step12"] === true &&
      delete initialValues.current[
        "Following people will be available to contact regarding this"
      ];
    skipValues.current["step13"] === true &&
      delete initialValues.current["Add this additional note"];
    setLoading(true);

    const releaseData = await getPressReleaseData(
      initialValues.current,
      apiKeyData
    );

    if (releaseData.status !== OK) {
      showError(releaseData.statusText);
      setLoading(false);
    }
    console.log(releaseData?.data?.choices[0], "data");
    if (releaseData.status === OK) {
      setLoading(false);
      setDataOfPressRelease(releaseData?.data?.choices[0].text);
    }

    /* if (releaseData !== "") {
      setLoading(false);
      setDataOfPressRelease(releaseData);
    } */
  };

  const generateMainData = async (inputtedData) => {
    setLoading(true);

    const releaseData = await getPressReleaseData(inputtedData, data);

    if (releaseData.status !== OK) {
      showError(releaseData.statusText);
      setLoading(false);
    }

    if (releaseData.status === OK) {
      setLoading(false);
      setDataOfPressRelease(releaseData?.data?.choices[0].text);
    }
  };

  return (
    <>
      <div class="header-logo">
        <img src={prideOfPR} class="main-logo" />
      </div>
      <div className="container">
        <div className="card">
          <div className="form">
            {/*  <div className="left-side" hidden={dataOfPressRelase !== ""}>
            <div className="left-heading">
              <img src={prideOfPR} className="prideofPR-logo" />
            </div>
            <div className="main-pf-bar-scroll">
              <ul className="progress-bar pointer-class">
                <li
                  className={`text-start ${currentStep >= 1 && "active"}`}
                  onClick={() => {
                    currentStep > 1 && setCurrentStep(1);
                    handleMixPanelClick(1);
                  }}
                >
                  This press release is generated by
                </li>
                <li
                  className={`text-start ${currentStep >= 2 && "active"}`}
                  onClick={() => {
                    currentStep > 2 && setCurrentStep(2);
                    handleMixPanelClick(2);
                  }}
                >
                  Tell us about the Announcement
                </li>
                <li
                  className={`text-start ${currentStep >= 3 && "active"}`}
                  onClick={() => {
                    currentStep > 3 && setCurrentStep(3);
                    handleMixPanelClick(3);
                  }}
                >
                  Who is the Target Audience
                </li>
                <li
                  className={`text-start ${currentStep >= 4 && "active"}`}
                  onClick={() => {
                    currentStep > 4 && setCurrentStep(4);
                    handleMixPanelClick(4);
                  }}
                >
                  Add Key Points
                </li>
                <li
                  className={`text-start ${currentStep >= 5 && "active"}`}
                  onClick={() => {
                    currentStep > 5 && setCurrentStep(5);
                    handleMixPanelClick(5);
                  }}
                >
                  Add Data
                </li>
                <li
                  className={`text-start ${currentStep >= 6 && "active"}`}
                  onClick={() => {
                    currentStep > 6 && setCurrentStep(6);
                    handleMixPanelClick(6);
                  }}
                >
                  Add Statistics{" "}
                </li>
                <li
                  className={`text-start ${currentStep >= 7 && "active"}`}
                  onClick={() => {
                    currentStep > 7 && setCurrentStep(7);
                    handleMixPanelClick(7);
                  }}
                >
                  Add Events
                </li>
                <li
                  className={`text-start ${currentStep >= 8 && "active"}`}
                  onClick={() => {
                    currentStep > 8 && setCurrentStep(8);
                    handleMixPanelClick(8);
                  }}
                >
                  Link Previous Communication{" "}
                </li>
                <li
                  className={`text-start ${currentStep >= 9 && "active"}`}
                  onClick={() => {
                    currentStep > 9 && setCurrentStep(9);
                    handleMixPanelClick(9);
                  }}
                >
                  Industry Trends{" "}
                </li>
                <li
                  className={`text-start ${currentStep >= 10 && "active"}`}
                  onClick={() => {
                    currentStep > 10 && setCurrentStep(10);
                    handleMixPanelClick(10);
                  }}
                >
                  Legal Disclaimer{" "}
                </li>
                <li
                  className={`text-start ${currentStep >= 11 && "active"}`}
                  onClick={() => {
                    currentStep > 11 && setCurrentStep(11);
                    handleMixPanelClick(11);
                  }}
                >
                  Jurisdiction{" "}
                </li>
                <li
                  className={`text-start ${currentStep >= 12 && "active"}`}
                  onClick={() => {
                    currentStep > 12 && setCurrentStep(12);
                    handleMixPanelClick(12);
                  }}
                >
                  Contact Information
                </li>
                <li
                  className={`text-start ${currentStep >= 13 && "active"}`}
                  onClick={() => {
                    currentStep > 13 && setCurrentStep(13);
                    handleMixPanelClick(13);
                  }}
                >
                  Additional Notes
                </li>
                <li
                  className={`text-start ${currentStep >= 14 && "active"}`}
                  onClick={() => {
                    currentStep > 14 && setCurrentStep(14);
                    handleMixPanelClick(14);
                  }}
                >
                  Select Framework
                </li>
                <li
                  className={`text-start ${currentStep >= 15 && "active"}`}
                  onClick={() => {
                    currentStep > 15 && setCurrentStep(15);
                    handleMixPanelClick(15);
                  }}
                >
                  Add your key
                </li>
              </ul>
            </div>
            <div class="copyright-txt">
              <span class="developed mr-3">Developed by</span>
              <a href="https://www.spaceotechnologies.com/" target="_blank">
                <img
                  class="cover-brand-image"
                  alt="Homepage"
                  src={logo}
                  title="cover-page-logo"
                  width="100"
                  height="35"
                />
              </a>
            </div>
          </div> */}
            <div className="right-side" hidden={dataOfPressRelase !== ""}>
              <div className="main active h-100">
                {/*  {currentStep === 1 && (
                <Step1
                  pressValues={initialValues.current}
                  currentStep={currentStep}
                  setCurrentStep={setCurrentStep}
                />
              )}
              {currentStep === 2 && (
                <Step2
                  pressValues={initialValues.current}
                  currentStep={currentStep}
                  setCurrentStep={setCurrentStep}
                />
              )}
              {currentStep === 3 && (
                <Step3
                  pressValues={initialValues.current}
                  currentStep={currentStep}
                  setCurrentStep={setCurrentStep}
                />
              )}
              {currentStep === 4 && (
                <Step4
                  pressValues={initialValues.current}
                  currentStep={currentStep}
                  setCurrentStep={setCurrentStep}
                />
              )}
              {currentStep === 5 && (
                <Step5
                  pressValues={initialValues.current}
                  currentStep={currentStep}
                  setCurrentStep={setCurrentStep}
                  skipValues={skipValues}
                />
              )}
              {currentStep === 6 && (
                <Step6
                  pressValues={initialValues.current}
                  currentStep={currentStep}
                  setCurrentStep={setCurrentStep}
                  skipValues={skipValues}
                />
              )}
              {currentStep === 7 && (
                <Step7
                  pressValues={initialValues.current}
                  currentStep={currentStep}
                  setCurrentStep={setCurrentStep}
                  skipValues={skipValues}
                />
              )}
              {currentStep === 8 && (
                <Step8
                  pressValues={initialValues.current}
                  currentStep={currentStep}
                  setCurrentStep={setCurrentStep}
                  skipValues={skipValues}
                />
              )}
              {currentStep === 9 && (
                <Step9
                  pressValues={initialValues.current}
                  currentStep={currentStep}
                  setCurrentStep={setCurrentStep}
                  skipValues={skipValues}
                />
              )}
              {currentStep === 10 && (
                <Step10
                  pressValues={initialValues.current}
                  currentStep={currentStep}
                  setCurrentStep={setCurrentStep}
                  skipValues={skipValues}
                />
              )}
              {currentStep === 11 && (
                <Step11
                  pressValues={initialValues.current}
                  currentStep={currentStep}
                  setCurrentStep={setCurrentStep}
                  skipValues={skipValues}
                />
              )}
              {currentStep === 12 && (
                <Step12
                  pressValues={initialValues.current}
                  currentStep={currentStep}
                  setCurrentStep={setCurrentStep}
                  skipValues={skipValues}
                />
              )}
              {currentStep === 13 && (
                <Step13
                  pressValues={initialValues.current}
                  currentStep={currentStep}
                  setCurrentStep={setCurrentStep}
                  skipValues={skipValues}
                />
              )}
              {currentStep === 14 && (
                <Step14
                  pressValues={initialValues.current}
                  currentStep={currentStep}
                  setCurrentStep={setCurrentStep}
                />
              )}
              {currentStep === 15 && (
                <Step15
                  pressValues={initialValues.current}
                  apiKeydata={apiKeydata}
                  data={data}
                  generateData={generateData}
                  loading={loading}
                />
              )} */}

                <MainPage
                  pressValues={mainFormInitialValue.current}
                  generateMainData={generateMainData}
                  clickCount={clickCount}
                />

                {/* <div className="buttons main-button">
                {currentStep < 15 ? (
                  <button
                    className="next_button"
                    onClick={() => {
                      setCurrentStep(currentStep + 1);
                    }}
                  >
                    Next Step
                  </button>
                ) : (
                  <button
                    className="next_button"
                    onClick={() => {
                      generateData();
                    }}
                    disabled={loading}
                  >
                    Submit
                  </button>
                )}
              </div> */}
                <div className="loading" hidden={!loading}>
                  <Loading />
                </div>
              </div>
            </div>
            <div hidden={dataOfPressRelase === ""}>
              <ResponseOfPressRelease
                dataOfPressRelase={dataOfPressRelase}
                className="main-side"
                title={
                  initialValues.current["announcement"]["Title of release is"]
                }
              />
            </div>
          </div>
        </div>
      </div>
      <div class="footer-logo">
        <span
          class="developed mr-3"
          onClick={() => setclickCount(clickCount + 1)}
        >
          Developed by Space-O Technologies
        </span>
        {/* <a href="https://www.spaceotechnologies.com/" target="_blank">
          <img
            class="cover-brand-image"
            alt="Homepage"
            src={logo}
            title="cover-page-logo"
            width="100"
            height="35"
          />
        </a> */}
      </div>
    </>
  );
}

export default App;
