import React from "react";
import DOMPurify from "dompurify";

export default function ResponseOfPressRelease({ dataOfPressRelase, title }) {
  const responseData = dataOfPressRelase.replace(/\n/g, "<br>");
  return (
    <>
      <h3 className="text-center mt-2">{title}</h3>
      <div dangerouslySetInnerHTML={{ __html: responseData }} className='press-release' />
    </>
  );
}
