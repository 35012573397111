import React from "react";
import { ToastContainer } from "react-toastify";

import config from "../config/config";

import "react-toastify/dist/ReactToastify.css";

const ToastNotification = () => {
  const { toastNotification } = config;
  return (
    <ToastContainer
      position={toastNotification.position}
      closeButton={true}
      autoClose={toastNotification.autoCloseTimeOut}
      hideProgressBar={toastNotification.hideProgress}
    />
  );
};

export default ToastNotification;
