import { Configuration, OpenAIApi } from "openai";
import jsonString from 'json-stringify-safe';
import axios from "axios";

export async function getPressReleaseData(_data,apiKeyData) {
    const configuration = new Configuration({
        apiKey: apiKeyData
    });
    delete _data.current
    
    const jsonData = jsonString(_data);
    const formattedData = JSON.stringify(jsonData)
    const openai = new OpenAIApi(configuration);
    const response = await openai.createCompletion({
        prompt: `write a press release for given data ${formattedData}, also give title to it.`,
        max_tokens: 1500,
        model: "text-davinci-003",
        temperature: 0.7,
    }).then((response) =>{
        return response
    }).catch((error)=>{
        return error.response
    });
   return response

}

/* export async function getPressReleaseData(_data, apiKeyData) {

    let data =''
    await axios.post('https://api.openai.com/v1/completions', {
        prompt: 'Give full information about france?',
        'model': "text-davinci-003",
        max_tokens: 2048,
    }, {
        responseType: 'json',
        params: {
            stream: true
        },
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${apiKeyData}`,
        },
        onDownloadProgress: (progressEvent) => {
                console.log(progressEvent,'event')
                if (data.includes("Done")) {
                    const data = progressEvent;
                }
                // handle the data

        }
    })
} */