import React from "react";

export default function Button({
  type,
  className,
  children,
  onClick,
  style,
  disabled,
  hidden,
}) {
  return (
    <div className="buttons main-button">
      <button
        type={type}
        className={className}
        onClick={onClick}
        style={style}
        disabled={disabled}
        hidden={hidden}
      >
        {children}
      </button>
    </div>
  );
}
