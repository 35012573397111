import * as Yup from 'yup';

export const Step1ValidationSchema = Yup.object().shape({
    "pressValues['This press release is generated by']['individual']['with name']": Yup.string()
        .required('Please enter name.'),
    /* type: Yup.string()
        .required('Please select any type.'),
    contact: Yup.string()
        .min(7, "Please enter a valid contact number.")
        .max(15, "Please enter a valid contact number.")
        .required("Contact number cannot be blank!"),
    email: Yup.string()
        .email("Please enter a valid email address.")
        .required("Email cannot be blank!"),
    address: Yup.string()
        .required("Please enter valid address."),
    website: Yup.string()
        .required('Please enter valid website.'),
    authorizedPerson: Yup.string()
        .required('Please enter valid authorized person name.') */
});

export const mainPageValidationSchema = Yup.object().shape({
    "The news angle is": Yup.string().required('Please enter the news angle.'),
    "The company information is": Yup.string().required('Please enter the company information.'),
    "The detailed information regarding announcement is": Yup.string().required('Please enter the company information.'),
    // "Product or service information is": Yup.string().required('Please enter product or service information.'),
    // "Event information is": Yup.string().required('Please enter event information.'),
    "Quote to be added is": Yup.string().required('Please enter Quote.'),
    "Contact information is": Yup.string().required('Please enter contact information.'),
    "Link of the document is": Yup.string().required('Please enter link of the document.'),
    "Target audience is": Yup.string().required('Please enter target audience.'),
    "Additional information is": Yup.string().required('Please enter additional information.'),
});
