/**
 * Basic Configuration
 */
const config = {
    // chatGPT api key
    apiKey: process.env.REACT_APP_CHATGPT_API_KEY,

    /**
   * Notification configuration
   */
    toastNotification: {
        /**
         * Toast Notification Position
         */
        position: "top-center",

        /**
         * Define time in mili second. Toast Notification will closed in time
         */
        autoCloseTimeOut: 1500,

        /**
         * it will show progress in notification
         */
        hideProgress: false,
    },

    mixpanelToken: process.env.REACT_APP_MIXPANEL_TOKEN
}

export default config;