import React, { useEffect } from "react";
import { Link } from "react-router-dom";
// import Mixpanel from "mixpanel-browser";

import logo from "../../assets/images/Spaceo-logo.svg";
import prideofPRLogo from "../../assets/images/PrideofPR_Logo.svg";

export default function CoverPage() {
  /* useEffect(() => {
    Mixpanel.track("Page Loaded", { Page: "CoverPage" });
  }, []);
 */
  /* const handleMixPressClick = () => {
    Mixpanel.track("Button Clicked", { Button: "Create new button clicked" });
  }; */

  return (
    <div className="container">
      <div className="cover-page text-center d-flex flex-column justify-content-center">
        <div className="main-logo mb-4">
          <img src={prideofPRLogo} className="prideofPR-logo" />
        </div>
        <div className="cover-title mb-4">
          <p className="mb-0 text-white">
            Create your Press Release in just few clicks.
          </p>
        </div>
        <div className="cover-buttons d-flex align-itens-center justify-content-center">
          {/*  <button type="button" className="view_button btn">
            View Demo
          </button> */}
          <Link to="/create-new">
            <button
              type="button"
              className="create_button btn"
              // onClick={handleMixPressClick}
            >
              Create New
            </button>
          </Link>
        </div>
        <div className="cover-footer">
          <span className="developed mr-3">
            Developed by Space-O Technologies
          </span>

          {/* <a href="https://www.spaceotechnologies.com/" target="_blank">
            <img
              className="cover-brand-image"
              alt="Homepage"
              src={logo}
              title="cover-page-logo"
              width="100"
              height="35"
            />
          </a> */}
        </div>
      </div>
    </div>
  );
}
