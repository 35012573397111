import React from "react";
import { Route, Routes } from "react-router-dom";

import RouteList from "./routes";

/**
 *
 * @returns Route
 */
const WrappedRoutes = () => (
  <Routes>{RouteList.map((Item, _index) => SimpleRoute(Item, _index))}</Routes>
);

const SimpleRoute = (Item, _index) => (
   
  <Route
    path={Item.path}
    element={<Item.Component props={Item} />}
    key={_index}
    />
   
);

export default WrappedRoutes;
