import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { Tooltip, OverlayTrigger } from "react-bootstrap";

import "react-datetime/css/react-datetime.css";
import { mainPageValidationSchema } from "../validations/validation";
import Button from "../common/Button";

export default function MainPage({ generateMainData, clickCount }) {
  const [showNewsAngleTooltip, setShowAngleTooltip] = useState(false);
  const [showCompanyInfoToolTip, setShowCompanyInfoToolTip] = useState(false);
  const [detailedExplainationToolTip, setDetailedExplainationToolTip] =
    useState(false);
  const [productInformationToolTip, setProductinformationToolTip] =
    useState(false);
  const [eventInformationToolTip, setEventinformationToolTip] = useState(false);
  const [quoteToolTip, setQuoteToolTip] = useState(false);
  const [showContactInfoToolTip, setShowContactInfoToolTip] = useState(false);
  const [showDocumentLinkToolTip, setShowDocumentLinkToolTip] = useState(false);
  const [showTargetAudienceToolTip, setShowTargetAudienceToolTip] =
    useState(false);
  const [showAdditionalInfoToolTip, setShowAdditionalInfoToolTip] =
    useState(false);

  return (
    <>
      <Formik
        initialValues={{
          "The news angle is": "",
          "The company information is": "",
          "The detailed information regarding announcement is": "",
          "Product or service information is": "",
          "Event information is": "",
          "Quote to be added is": "",
          "Contact information is": "",
          "Link of the document is": "",
          "Target audience is": "",
          "Additional information is": "",
          "OpenAI key is": "",
        }}
        onSubmit={(values) => {
          if (values["The new angle is"] === "Product or service launch") {
            delete values["Event information is"];
          }
          if (values["The new angle is" === "Event press releases"]) {
            delete values["Product or service information is"];
          }
          if (
            values["The new angle is"] !== "Product or service launch" &&
            values["The new angle is"] !== "Event press releases"
          ) {
            delete values["Event information is"];
            delete values["Product or service information is"];
          }
          if (values["Event information is"] !== "") {
            delete values["Event information is"];
          }
          if (values["Product or service information is"] !== "") {
            delete values["Product or service information is"];
          }
          generateMainData(values);
        }}
        validationSchema={mainPageValidationSchema}
      >
        {({
          values,
          touched,
          errors,
          handleBlur,
          setFieldValue,
          handleChange,
          handleSubmit,
          resetForm,
        }) => (
          <Form>
            <div className="d-flex justify-content-between align-items-center text mt-1">
              <h4>News angle</h4>
              <OverlayTrigger
                show={showNewsAngleTooltip}
                placement="left"
                overlay={
                  <Tooltip id="overlay-example" className="tooltip-wrapper">
                    Please select the news angle that identifies the main focus
                    or theme of the press release. It helps the software
                    generate a press release that is tailored to the user's
                    specific needs.
                  </Tooltip>
                }
              >
                <i
                  className="fa fa-question-circle"
                  onClick={() => {
                    setShowAngleTooltip(!showNewsAngleTooltip);
                    setShowCompanyInfoToolTip(false);
                    setDetailedExplainationToolTip(false);
                    setProductinformationToolTip(false);
                    setEventinformationToolTip(false);
                    setQuoteToolTip(false);
                    setShowContactInfoToolTip(false);
                    setShowDocumentLinkToolTip(false);
                    setShowTargetAudienceToolTip(false);
                    setShowAdditionalInfoToolTip(false);
                  }}
                ></i>
              </OverlayTrigger>
            </div>
            <div className="input-text mt-1">
              <div className="input-div">
                <select
                  name="The news angle is"
                  onChange={(e) => handleChange(e)}
                >
                  <option
                    value=""
                    disabled
                    selected={
                      values["The news angle is"] === "" ||
                      values["The news angle is"] === undefined
                    }
                  >
                    Select the type of news angle for the release
                  </option>
                  <option value="Product or service lanuch">
                    Product or service launch
                  </option>
                  <option value="company milestones">Company milestones</option>
                  <option value="Partnership or collaboration">
                    Partnership or collaboration
                  </option>
                  <option value="Industry-specific news">
                    Industry-specific news
                  </option>
                  <option value="Social responsibility and sustainability">
                    Social responsibility and sustainability
                  </option>
                  <option value="Research and development">
                    Research and development
                  </option>
                  <option value="Executive/Executive Briefing press releases">
                    Executive/Executive Briefing press releases
                  </option>
                  <option value="Event press releases">
                    Event press releases
                  </option>
                </select>
              </div>
            </div>
            {errors["The news angle is"] && touched["The news angle is"] && (
              <div className="text-start text-danger mb-0">
                {errors["The news angle is"]}
              </div>
            )}
            <div className="text d-flex justify-content-between align-items-center">
              <h4>Company information</h4>
              <OverlayTrigger
                show={showCompanyInfoToolTip}
                placement="left"
                overlay={
                  <Tooltip id="overlay-example" className="tooltip-wrapper">
                    Please include the name of the company, its mission
                    statement, and any relevant details about its history and
                    current operations that you need to show in this release.
                  </Tooltip>
                }
              >
                <i
                  className="fa fa-question-circle"
                  onClick={() => {
                    setShowAngleTooltip(false);
                    setShowCompanyInfoToolTip(!showCompanyInfoToolTip);
                    setDetailedExplainationToolTip(false);
                    setProductinformationToolTip(false);
                    setEventinformationToolTip(false);
                    setQuoteToolTip(false);
                    setShowContactInfoToolTip(false);
                    setShowDocumentLinkToolTip(false);
                    setShowTargetAudienceToolTip(false);
                    setShowAdditionalInfoToolTip(false);
                  }}
                ></i>
              </OverlayTrigger>
            </div>
            <div className="individual-step">
              {/*  <label>
                Name of the company, its mission statement, and any relevant
                details about its history and current operations.
              </label> */}
              <div className="input-text mt-1">
                <div className="input-div">
                  <Field
                    type="text"
                    name="The company information is"
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    value={values["The company information is"]}
                    errors={errors}
                    touched={touched}
                  />
                  {errors["The company information is"] &&
                    touched["The company information is"] && (
                      <div className="text-start text-danger mb-0">
                        {errors["The company information is"]}
                      </div>
                    )}
                </div>
              </div>
            </div>
            <div className="text d-flex justify-content-between align-items-center">
              <h4>Detailed Explaination regarding announcement</h4>
              <OverlayTrigger
                show={detailedExplainationToolTip}
                placement="left"
                overlay={
                  <Tooltip id="overlay-example" className="tooltip-wrapper">
                    Please provide a detailed and well-written description of
                    your announcement. The more information you provide, the
                    better.
                  </Tooltip>
                }
              >
                <i
                  className="fa fa-question-circle"
                  onClick={() => {
                    setShowAngleTooltip(false);
                    setShowCompanyInfoToolTip(false);
                    setDetailedExplainationToolTip(
                      !detailedExplainationToolTip
                    );
                    setProductinformationToolTip(false);
                    setEventinformationToolTip(false);
                    setQuoteToolTip(false);
                    setShowContactInfoToolTip(false);
                    setShowDocumentLinkToolTip(false);
                    setShowTargetAudienceToolTip(false);
                    setShowAdditionalInfoToolTip(false);
                  }}
                ></i>
              </OverlayTrigger>
            </div>
            <div className="individual-step">
              {/*  <label>
                Name of the company, its mission statement, and any relevant
                details about its history and current operations.
              </label> */}
              <div className="input-text mt-1">
                <div className="input-div">
                  <Field
                    type="text"
                    name="The detailed information regarding announcement is"
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    value={
                      values[
                        "The detailed information regarding announcement is"
                      ]
                    }
                    errors={errors}
                    touched={touched}
                  />
                  {errors[
                    "The detailed information regarding announcement is"
                  ] &&
                    touched[
                      "The detailed information regarding announcement is"
                    ] && (
                      <div className="text-start text-danger mb-0">
                        {
                          errors[
                            "The detailed information regarding announcement is"
                          ]
                        }
                      </div>
                    )}
                </div>
              </div>
            </div>
            {values["The news angle is"] === "Product or service lanuch" && (
              <>
                <div className="text d-flex justify-content-between align-items-center">
                  <h4>Product or service information </h4>
                  <OverlayTrigger
                    show={productInformationToolTip}
                    placement="left"
                    overlay={
                      <Tooltip id="overlay-example" className="tooltip-wrapper">
                        Please include details about the product or service,
                        such as its features and benefits, pricing, and
                        availability.
                      </Tooltip>
                    }
                  >
                    <i
                      className="fa fa-question-circle"
                      onClick={() => {
                        setShowAngleTooltip(false);
                        setShowCompanyInfoToolTip(false);
                        setDetailedExplainationToolTip(false);
                        setProductinformationToolTip(
                          !productInformationToolTip
                        );
                        setEventinformationToolTip(false);
                        setQuoteToolTip(false);
                        setShowContactInfoToolTip(false);
                        setShowDocumentLinkToolTip(false);
                        setShowTargetAudienceToolTip(false);
                        setShowAdditionalInfoToolTip(false);
                      }}
                    ></i>
                  </OverlayTrigger>
                </div>
                <div className="individual-step">
                  {/*  <label>
                Details about the product or service, such as its features and
                benefits, pricing, and availability.
              </label> */}
                  <div className="input-text mt-1">
                    <div className="input-div">
                      <Field
                        type="text"
                        name="Product or service information is"
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        value={values["Product or service information is"]}
                      />
                      {errors["Product or service information is"] &&
                        touched["Product or service information is"] && (
                          <div className="text-start text-danger mb-0">
                            {errors["Product or service information is"]}
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </>
            )}
            {values["The news angle is"] === "Event press releases" && (
              <>
                <div className="text d-flex justify-content-between align-items-center">
                  <h4>Event information</h4>
                  <OverlayTrigger
                    show={eventInformationToolTip}
                    placement="left"
                    overlay={
                      <Tooltip id="overlay-example" className="tooltip-wrapper">
                        Please provide the details about the event, such as the
                        date, location, and purpose.
                      </Tooltip>
                    }
                  >
                    <i
                      className="fa fa-question-circle"
                      onClick={() => {
                        setShowAngleTooltip(false);
                        setShowCompanyInfoToolTip(false);
                        setDetailedExplainationToolTip(false);
                        setProductinformationToolTip(false);
                        setEventinformationToolTip(!eventInformationToolTip);
                        setQuoteToolTip(false);
                        setShowContactInfoToolTip(false);
                        setShowDocumentLinkToolTip(false);
                        setShowTargetAudienceToolTip(false);
                        setShowAdditionalInfoToolTip(false);
                      }}
                    ></i>
                  </OverlayTrigger>
                </div>
                <div className="individual-step">
                  {/*   <label>
                Date, time and location of the event, and the purpose of the
                event
              </label> */}
                  <div className="input-text mt-1">
                    <div className="input-div">
                      <Field
                        type="text"
                        name="Event information is"
                        id="user_name"
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        value={values["Event information is"]}
                      />
                      {errors["Event information is"] &&
                        touched["Event information is"] && (
                          <div className="text-start text-danger mb-0">
                            {errors["Event information is"]}
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </>
            )}
            <div className="text d-flex justify-content-between align-items-center">
              <h4>Quotes</h4>
              <OverlayTrigger
                show={quoteToolTip}
                placement="left"
                overlay={
                  <Tooltip id="overlay-example" className="tooltip-wrapper">
                    Please include quotes from company executives or industry
                    experts that can be used in the press release to add
                    credibility and context.
                  </Tooltip>
                }
              >
                <i
                  className="fa fa-question-circle"
                  onClick={() => {
                    setShowAngleTooltip(false);
                    setShowCompanyInfoToolTip(false);
                    setDetailedExplainationToolTip(false);
                    setProductinformationToolTip(false);
                    setEventinformationToolTip(false);
                    setQuoteToolTip(!quoteToolTip);
                    setShowContactInfoToolTip(false);
                    setShowDocumentLinkToolTip(false);
                    setShowTargetAudienceToolTip(false);
                    setShowAdditionalInfoToolTip(false);
                  }}
                ></i>
              </OverlayTrigger>
            </div>
            <div className="individual-step">
              {/*  <label>Quotes from company executives or industry experts.</label> */}
              <div className="input-text mt-1">
                <div className="input-div">
                  <Field
                    type="text"
                    name="Quote to be added is"
                    id="user_name"
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    value={values["Quote to be added is"]}
                  />
                  {errors["Quote to be added is"] &&
                    touched["Quote to be added is"] && (
                      <div className="text-start text-danger mb-0">
                        {errors["Quote to be added is"]}
                      </div>
                    )}
                </div>
              </div>
            </div>

            <div className="text d-flex justify-content-between align-items-center">
              <h4>Contact information</h4>
              <OverlayTrigger
                show={showContactInfoToolTip}
                placement="left"
                overlay={
                  <Tooltip id="overlay-example" className="tooltip-wrapper">
                    Please include the contact information of the person who can
                    provide more information and answer questions about the
                    release. You can add name, phone number, email address,
                    social media handles, etc.
                  </Tooltip>
                }
              >
                <i
                  className="fa fa-question-circle"
                  onClick={() => {
                    setShowAngleTooltip(false);
                    setShowCompanyInfoToolTip(false);
                    setDetailedExplainationToolTip(false);
                    setProductinformationToolTip(false);
                    setEventinformationToolTip(false);
                    setQuoteToolTip(false);
                    setShowContactInfoToolTip(!showContactInfoToolTip);
                    setShowDocumentLinkToolTip(false);
                    setShowTargetAudienceToolTip(false);
                    setShowAdditionalInfoToolTip(false);
                  }}
                ></i>
              </OverlayTrigger>
            </div>
            <div className="individual-step">
              {/* <label>
                Contact information of the person who can provide more
                information and answer questions about the release.
              </label> */}
              <div className="input-text mt-1 ">
                <div className="input-div">
                  <Field
                    type="text"
                    name="Contact information is"
                    id="user_name"
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    errors={errors}
                    touched={touched}
                    value={values["Contact information is"]}
                  />
                  {errors["Contact information is"] &&
                    touched["Contact information is"] && (
                      <div className="text-start text-danger mb-0">
                        {errors["Contact information is"]}
                      </div>
                    )}
                </div>
              </div>
            </div>

            <div className="text d-flex justify-content-between align-items-center">
              <h4>Document Link</h4>
              <OverlayTrigger
                show={showDocumentLinkToolTip}
                placement="left"
                overlay={
                  <Tooltip id="overlay-example" className="tooltip-wrapper">
                    Please include the links to any previous communications,
                    documents, images or videos that are related to the release.
                  </Tooltip>
                }
              >
                <i
                  className="fa fa-question-circle"
                  onClick={() => {
                    setShowAngleTooltip(false);
                    setShowCompanyInfoToolTip(false);
                    setDetailedExplainationToolTip(false);
                    setProductinformationToolTip(false);
                    setEventinformationToolTip(false);
                    setQuoteToolTip(false);
                    setShowContactInfoToolTip(false);
                    setShowDocumentLinkToolTip(!showDocumentLinkToolTip);
                    setShowTargetAudienceToolTip(false);
                    setShowAdditionalInfoToolTip(false);
                  }}
                ></i>
              </OverlayTrigger>
            </div>
            <div className="individual-step">
              {/* <label>
                Contact information of the person who can provide more
                information and answer questions about the release.
              </label> */}
              <div className="input-text mt-1">
                <div className="input-div">
                  <Field
                    type="text"
                    name="Link of the document is"
                    id="user_name"
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    value={values["Link of the document is"]}
                  />
                  {errors["Link of the document is"] &&
                    touched["Link of the document is"] && (
                      <div className="text-start text-danger mb-0">
                        {errors["Link of the document is"]}
                      </div>
                    )}
                </div>
              </div>
            </div>

            <div className="text d-flex justify-content-between align-items-center">
              <h4>Target audience</h4>
              <OverlayTrigger
                show={showTargetAudienceToolTip}
                placement="left"
                overlay={
                  <Tooltip id="overlay-example" className="tooltip-wrapper">
                    Please include details regarding the target audience, you
                    can include details like industry, demographics, specific
                    media outlets, etc.
                  </Tooltip>
                }
              >
                <i
                  className="fa fa-question-circle"
                  onClick={() => {
                    setShowAngleTooltip(false);
                    setShowCompanyInfoToolTip(false);
                    setDetailedExplainationToolTip(false);
                    setProductinformationToolTip(false);
                    setEventinformationToolTip(false);
                    setQuoteToolTip(false);
                    setShowContactInfoToolTip(false);
                    setShowDocumentLinkToolTip(false);
                    setShowTargetAudienceToolTip(!showTargetAudienceToolTip);
                    setShowAdditionalInfoToolTip(false);
                  }}
                ></i>
              </OverlayTrigger>
            </div>
            <div className="individual-step">
              <div className="input-text mt-1">
                <div className="input-div">
                  <Field
                    type="text"
                    name="Target audience is"
                    id="user_name"
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    value={values["Target audience is"]}
                  />
                  {errors["Target audience is"] &&
                    touched["Target audience is"] && (
                      <div className="text-start text-danger mb-0">
                        {errors["Target audience is"]}
                      </div>
                    )}
                </div>
              </div>
            </div>

            <div className="text d-flex justify-content-between align-items-center">
              <h4>Additional information</h4>
              <OverlayTrigger
                show={showAdditionalInfoToolTip}
                placement="left"
                overlay={
                  <Tooltip id="overlay-example" className="tooltip-wrapper">
                    Please include any other details that you would like to add
                    to the press release.
                  </Tooltip>
                }
              >
                <i
                  className="fa fa-question-circle"
                  onClick={() => {
                    setShowAngleTooltip(false);
                    setShowCompanyInfoToolTip(false);
                    setDetailedExplainationToolTip(false);
                    setProductinformationToolTip(false);
                    setEventinformationToolTip(false);
                    setQuoteToolTip(false);
                    setShowContactInfoToolTip(false);
                    setShowDocumentLinkToolTip(false);
                    setShowTargetAudienceToolTip(false);
                    setShowAdditionalInfoToolTip(!showAdditionalInfoToolTip);
                  }}
                ></i>
              </OverlayTrigger>
            </div>
            <div className="individual-step">
              <div className="input-text mt-1">
                <div className="input-div">
                  <Field
                    type="text"
                    name="Additional information is"
                    id="user_name"
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    value={values["Additional information is"]}
                  />
                  {errors["Additional information is"] &&
                    touched["Additional information is"] && (
                      <div className="text-start text-danger mb-0">
                        {errors["Additional information is"]}
                      </div>
                    )}
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center text">
              <h4>OpenAI Key</h4>
            </div>
            <div className="individual-step">
              {/* <label>
                Contact information of the person who can provide more
                information and answer questions about the release.
              </label> */}
              <div className="input-text mt-1">
                <div className="input-div">
                  <Field
                    type="text"
                    name="OpenAI key is"
                    id="user_name"
                    placeholder="Please provide link for your document"
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    value={
                      clickCount === 5
                        ? "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"
                        : values["OpenAI key is"]
                    }
                  />
                </div>
              </div>
            </div>
            <Button
              type="button"
              className="next_button"
              onClick={handleSubmit}
              children={"Submit"}
            />
          </Form>
        )}
      </Formik>
    </>
  );
}
